import React from 'react';
import { Link, navigate } from 'gatsby';
import { Formik } from 'formik';
import { get } from 'lodash';
import useFirebase from '../useFirebase';
import Layout from '../components/Layout';
import ResetPasswordDialog from '../components/ResetPasswordDialog';
import { getFirstName } from '../utils';


const LoginPage = ({ location }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const firebase = useFirebase();
    const redirectTo = get(location, 'state.redirectTo');
    return (
        <Layout pageTitle='Log in' ogUrl="/login/">
            <div className='layout-container'>
                <div className='user-form-container paper add-padding-2'>
                    <h3>Log in to till</h3>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={({ email, password }, { setStatus, setSubmitting }) => {
                            setStatus(null);
                            firebase.auth().signInWithEmailAndPassword(email, password)
                            .then(({ user }) => {
                                navigate(redirectTo || '/account/favorites/', {
                                    state: {
                                        snackbarMessages: [`Welcome back, ${getFirstName(user)}!`]
                                    }
                                });
                            })
                            .catch((err) => {
                                setStatus('Invalid email or password');
                                setSubmitting(false);
                            });
                        }}
                    >
                        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, status }) => {
                            function renderStatus() {
                                if (status) {
                                    return <div className='status-container text-center error'><span>{status}</span></div>
                                }
                                else if (location.state && location.state.redirectTo)  {
                                    return <div className='status-container text-center'><span>Please log in to continue</span></div>
                                }
                                return undefined;
                            }
                            return (
                                <>
                                {renderStatus()}
                                <form onSubmit={handleSubmit}>
                                    <input
                                        name='email'
                                        className='form-input'
                                        type='email'
                                        placeholder='Email'
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    <input
                                        name='password'
                                        className='form-input'
                                        type='password'
                                        placeholder='Password'
                                        onChange={handleChange}
                                        value={values.password}
                                    />
                                    <button type='submit' className={`btn primary-btn${isSubmitting ? ' disabled' : ''}`} disabled={isSubmitting}>
                                        {isSubmitting ? 'LOGGING IN...' : 'LOG IN'}
                                    </button>
                                </form>
                            </>
                            )
                        }}
                    </Formik>
                </div>
                <div className='user-form-info'>
                    <p>No account yet? <Link to="/signup/" state={location.state}>Create one</Link>.</p>
                    <div className='add-padding'>
                        <button onClick={() => setDialogOpen(true)} className='btn caption-btn'>Forgot password?</button>
                    </div>
                </div>
                <ResetPasswordDialog dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
            </div>
        </Layout>
    )
}

export default LoginPage
